import React from "react";
import latteArt from "../Images/latte-art.webp";
import coffeeFarm from "../Images/coffee-field.webp";
import coffeebeans from "../Images/coffeebeans.webp";
import { Link } from 'react-router-dom'
import '../App.css'

function Blog() {
  return (
    <section>
      <div className="blog-container">
        <div
          className="blog-background"
          style={{ backgroundImage: `url(${coffeebeans})` }}
        >
          <div className="blog-card">
            <div className="blog-content">
              <h4 className="blog-tag">Learn</h4>
              <h3 className="blog-h3">Explore Product's</h3>
              <Link to='/' className="blog-btn">Read More</Link>
            </div>
          </div>
        </div>
        <div
          className="blog-background"
          style={{ backgroundImage: `url(${latteArt})` }}
        >
          <div className="blog-card">
            <div className="blog-content">
              <h4 className="blog-tag">Become a Memeber</h4>
              <h3 className="blog-h3">Barista Training </h3>
              <Link to='/' className="blog-btn">Read More</Link>
            </div>
          </div>
        </div>
        <div
          className="blog-background"
          style={{ backgroundImage: `url(${coffeeFarm})` }}
        >
          <div className="blog-card">
            <div className="blog-content">
              <h4 className="blog-tag">Learn</h4>
              <h3 className="blog-h3">Explore Product's</h3>
              <Link to='/' className="blog-btn">Read More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;

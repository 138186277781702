import React from 'react'
import coffeeBean from '../Images/coffeebeans.jpeg'
import latteart from '../Images/latte-art.jpeg'
import bar from '../Images/coffee-bar.webp'
import coffee_articles from '../Data/article-data'

function RecommendedArticle(){
    console.log(coffee_articles)
    return(
        <section className='recommended-article-div'>               
            <div className='recommended-div'>
           <h4>Recommended for you</h4>
           <div className='article-flex'>
                {coffee_articles.map((itemText, index) => (
           <div className='recomended-container'>
               <div className='recomened-img-div'>
                   <img alt={itemText['alt-tag']} className='recomended-img' src={itemText['article-img']}/>
               </div>
               <div className='img-text-container'>
                   <h5 className='img-text-title'>
                       {itemText.title}
                   </h5>
                   <p className='img-text-date'>
                       {itemText.date}
                   </p>
                   <p className='recomended-discription'>{itemText.summary}</p>
               </div>
           </div>            
      ))}
      </div>
       </div>
        </section>
    )
}

export default RecommendedArticle;
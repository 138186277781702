import React from 'react'
import latteArt from '../Images/latte-art.jpeg'
import { CiInstagram } from "react-icons/ci";
import { BsTwitterX } from "react-icons/bs";
import {useEffect} from 'react'
import RecommendedArticle from './RecommenedArticle'


function Article (){
    useEffect(() => {
        document.title = 'Perfect Pour Over | Brewing the perfect Pour Over Coffee: A Step by Step Guide';
      }, []);
    return(
        <section className='article-page'>
            <title> Brewing the perfect Pour Over Coffee: A Step by Step Guide</title>
            <div className='article-div'>
                <div className='article-heading'>
                <h6 className='article-tag'>Learn</h6>            
                <h1 className='article-h1'>The Perfect way to make a Pour Over</h1>
                <p className='article-date'>12-9-23</p>
                </div>
                <div className='article-img-div'>
                    <img className='article-img' src={latteArt}/>
                </div>
                <div className='article-content'>
                    <div className='article-text'>
                <h3 className='article-h3'>Step 1: Choose the Right Beans and Grind Size</h3>
                <p className='article-p'>The foundation of a perfect pour-over coffee is excellent coffee beans. Opt for freshly roasted beans, ideally no older than two to four weeks. When grinding your beans, aim for a medium-fine consistency, resembling sea salt. Adjust the grind size according to your specific pour-over method.</p>
                <h3 className='article-h3'>Step 2: Pre-wet the Filter</h3>
                <p className='article-p'>Place the paper filter into the pour-over cone or brewer. Rinse the filter with hot water to remove any paper taste and warm up the brewer. Discard the rinse water. This step is crucial to ensure your coffee tastes clean and free of any paper-related flavors.</p>
                <h3 className='article-h3'>Step 3: Measure and Add Coffee Grounds</h3>
                <p className='article-p'>Use your digital kitchen scale to weigh the coffee grounds. A standard coffee-to-water ratio for pour-over coffee is typically around 1:16, meaning 1 gram of coffee to 16 grams of water. Adjust this ratio according to your taste preferences.</p>
                <h3 className='article-h3'>Step 4: The Blooming Process</h3>
                <p className='article-p'>The blooming process is a critical step that allows the coffee grounds to release trapped gases, ensuring even extraction. To bloom the coffee:</p>
                <ol className='article-ol'>
                  <li className='article-li'>Place the pour-over cone on top of your coffee mug.</li>  
                  <li className='article-li'>Add the coffee grounds.</li>
                  <li className='article-li'>Start the timer</li>
                  <li className='article--li'>Gently pour hot water (about twice the weight of the coffee grounds) evem;y over the grounds in a circular motion. </li>
                  <li className='article--li'>Watch as the coffee blooms and releases bubbles. This process usually takes about 30-45 seconds.</li>
                </ol>
                <h3 className='article-h3'>Step 5: The Pouring Technique</h3>
                <p className='article-p'>Once the bloom is complete, continue pouring hot water in a controlled manner, spiraling from the center to the outer edges, making sure all grounds are evenly saturated. Keep the water level consistent by adding it slowly and steadily. Avoid pouring directly onto the paper filter, as this can lead to uneven extraction.</p>
                <h3 className='article-h3'>Step 6: Wait for the Drip</h3>
                <p className='article-p'>As you pour the water over the coffee grounds, allow it to drip into the mug. The total brew time should typically range from 2 to 4 minutes, depending on your grind size and the amount of coffee you're making. Experiment to find your ideal brew time.</p>
                <h3 className='article-h3'>Step 7: Enjoy Your Perfect Pour-Over</h3>
                <p className='article-p'>Once the coffee has finished dripping, remove the pour-over cone, and your perfectly brewed coffee is ready to enjoy. Savor the aroma and flavors of your meticulously crafted cup.</p>
                <h3 className='article-h3'>Conclusion:</h3>
                <p className='article-p'>Brewing the perfect pour-over coffee is an art that combines precision and patience. By following these simple steps, including the crucial blooming process and filtering to remove any paper taste, you can elevate your coffee experience to new heights. Experiment with different beans, grind sizes, and pour-over methods to find the flavor profile that suits your palate best. Happy brewing!</p>                
                </div>
                <div className='article-socials-row'>
                    <div className='social-container'>
                    <p className='article-socials'>Share</p>
                    <img className='article-icon' />
                    <CiInstagram className='article-icon insta' />
                    <BsTwitterX className='article-icon x'/>
                    </div>
                </div>
                </div>
            </div>
            <RecommendedArticle/>
        </section>
    )
}

export default Article
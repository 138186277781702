 let coffee_articles = [
    {
        "title": "How to Brew Coffee Like a Barista",
        "summary": "Learn the art of brewing the perfect cup of coffee with tips on choosing beans, grinding, water temperature, and various brewing methods to elevate your coffee experience.",
        "key_points": [
            "Choose quality beans for better flavor.",
            "Grind your beans according to the brewing method.",
            "Use filtered water heated to the optimal temperature.",
            "Experiment with brew ratios and methods.",
            "Practice and refine your technique."
        ],
        "date": "1-1-24",
        "article-img": "https://i.postimg.cc/bY9s3XxT/latte-art-perfect-pour-over.jpg",
        "alt-tag": "How to Brew Coffee like a barista perfect pour over"        
    },
    {
        "title": "The Ultimate Guide to Coffee Beans",
        "summary": "Discover the world of coffee beans, from the differences between Arabica and Robusta to understanding roast profiles and how they affect your cup's flavor.",
        "key_points": [
            "Explore the types of coffee beans: Arabica vs. Robusta.",
            "Learn about the significance of roast levels.",
            "Understand how origin influences flavor profiles.",
            "Tips for storing coffee beans to maintain freshness."
        ],
        "date": "2-10-24",
        "article-img": "https://i.postimg.cc/Mp66HXg2/coffeebeans.webp",
        "alt-tag": "coffee beans perfect pour over"        
    },
    {
        "title": "Cold Brew Coffee: A Step-by-Step Guide",
        "summary": "Master the art of cold brew coffee with this comprehensive guide, including the benefits of cold brewing, how to choose your beans, and making the perfect cup at home.",
        "key_points": [
            "Benefits of cold brew over traditional hot brewing methods.",
            "Choosing the right coffee beans and grind size for cold brew.",
            "Step-by-step instructions for making cold brew at home.",
            "Ideas for serving and enjoying your homemade cold brew."
        ],
        "date": "3-10-24",
        "article-img": "https://i.postimg.cc/R0jqW4c7/cold-brew-perfect-pour-over.jpg",
        "alt-tag": "cold brew perfect pour over"
    }
]

export default coffee_articles
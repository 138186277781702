import React from "react";
import '../App.css'
import { useEffect } from 'react'
import coffee_articles from "../Data/article-data";
import Image from '../Images/Coffee.jpeg'
function Hero() {
  const backgroundStyle = {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    objectFit: 'cover',
    display: 'block',
    width: '100%',
    paddingTop: '300px',
    paddingBottom:' 200px',
  };

  useEffect(() => {
    document.title = 'Perfect Pour Over';
  }, []);
  return (
    <section  style={backgroundStyle}>
      <div className="hero-content">
        <h2 className="hero-h2">Discover the Easiest way to Master Coffee</h2>
        <h1 className="hero-h1">
          Start your day with the Perfect Cup, <br /> for everyday enthusiasts.
        </h1>
        <div className="hero-line-break"></div>
        <p className="hero-p-tag">
          Our goal is to offer accessible coffee knowledge for everyday
          consumers, simplifying the world of coffee without the fancy jargon.
        </p>
      </div>
    </section>
  );
}

export default Hero;

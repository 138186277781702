import React from "react";
import '../App.css';
import { useEffect } from 'react'

function Form() {
  useEffect(() => {
    document.title = 'Perfect Pour Over | Contact';
  }, []);
  return (
    <section className="form-section">
      <div className="form-left" >
      </div>
      <div className="form">
        <div className="form-header">
          <h1 className="form-h1">Welcome to Perfect Pour Over sign up to our Newsletter!</h1>
          <p className="form-p">
            We will give you easy to comprehend coffee articles to expand your
            knowlege on coffee weekly!
          </p>
        </div>
        <div className="form-row"  >
        <label className="form-label"> Contact name:<br/>
        <input className="input" />
        </label>
        </div>
        <br />
        <div className="form-row">
        <label className="form-label">Email:
          <br/>
        <input className="input" />
        </label>
        </div>
        <br/>
        <button className="sign-up-btn">
          Sign Up
        </button>
      </div>
    </section>
  );
}

export default Form;

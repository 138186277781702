import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
// import {coffeeBeans} from '../Images/'
import { Link } from 'react-router-dom'
import '../App.css'

function Nav() {

  
  return (
    <section className="nav-bar">
      <div className="nav-row">
        <Link to='/' className="nav-h2">Perfect Pour Over</Link>
        <ul className="nav-items">
          <li>
            <Link className="nav-href" to="/">Home</Link>
          </li>
          <li>
            <Link className="nav-href" to="/">Coffee Ratio</Link>
          </li>
          <li>
            <Link className="nav-href" to="/">Shop</Link>
          </li>
          <li>
            <Link className="nav-href" to="/article">Article's</Link>
          </li>
          <li>
            <Link className="nav-href" to='form'>Contact</Link>
          </li>
        </ul>
        {/* Hamburger Menu */}
        <div class="hamburger-menu">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>

        </div>
    </section>
  );
}

export default Nav;

import React from "react";
import { CiInstagram } from "react-icons/ci";
import { BsTwitterX } from "react-icons/bs";
import '../App.css'

function Footer() {
  return (
    <section className="footer">
      <div className="footer-content">
        <div className="footer-coloum">
          <h3 className="footer-h3">About</h3>
          <p className="footer-p"><a className="footer-p">Our Mission</a></p>
          <p className="footer-p"><a className="footer-p">FAQ</a></p>
        </div>
        <div className="footer-coloum">
          <h3 className="footer-h3">Locations</h3>
          <p className="footer-p"><a className="footer-p">Oxnard</a></p>
          <p className="footer-p"><a className="footer-p">Ventura</a></p>
        </div>
        <div className="footer-coloum">
          <h3 className="footer-h3">Follow Along</h3>
          <div className="svg-row">
            <CiInstagram className="social-svg-insta" />
            <BsTwitterX className="social-svg-x" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;

import Nav from "./Components/Nav.js";
import Hero from "./Components/Hero.js";
import Blog from "./Components/Blog.jsx";
import Footer from "./Components/Footer.jsx";
import Form from "./Components/Form.jsx";
import Article from './Components/Article.jsx'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/">
            <Hero />
            <Blog />
          </Route>
          <Route path='/article'>
              <Article />
          </Route>
          <Route path="/form">
            <Form />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
